import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"

import events from "../data/events.json"
import EventListItem from "../components/event-list-item"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h2>Em breve</h2>
    <p>Em breve nossos eventos listados.</p>
  </Layout>
)

export default IndexPage
